import axios from "axios";
import Axios from "../config/axios.js";
import { v4 as uuidv4 } from "uuid";
import { getUser } from "../utils/UserDetails.js";
class CareerService {
  getroadmap = async (formdata) => {
    const url = process.env.REACT_APP_AISTUDENT_URL + "/api-career-roadmap";
    const headers = !getUser()
      ? {
          "X-User-Identifier": uuidv4(),
          "X-Profile-Identifier": uuidv4(),
        }
      : {};
    return Axios.post("/career-roadmap", formdata, { headers })
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fitForRole = async (formdata) => {
    const headers = !getUser()
      ? {
          "X-User-Identifier": uuidv4(),
          "X-Profile-Identifier": uuidv4(),
        }
      : {};

    return Axios.post("/check-fit", formdata, { headers })
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deviceInfo = async (uniqueIdentifier, device) => {
    return Axios.post(
      "/road-map-limit",
      {}, // Empty object since you're not sending data in the body
      {
        headers: {
          "X-User-Identifier": uniqueIdentifier,
          deviceInfo: device, // Replace 'Custom-Header' with the actual header key
        },
      },
    )
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  VerifyLimit = (data) => {
    return Axios.post("/access-limit", data)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const CareerServiceInstance = new CareerService();
export default CareerServiceInstance;
