import React, { useEffect, useState } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import ServerGlobalFilter from "./ServerGlobalFilter";

const ServerMasterTable = ({
  batchParam,
  setBatchParam,
  columns,
  data,
  checkBoxChecked,
  selectAllRef,
  alphabetData,
}) => {
  const [pageSize, setPageSize] = useState(
    batchParam?.limit ? batchParam?.limit : 50,
  );

  const pageSizeOptions = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: data[0]?.count, label: "All" },
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    footerGroups,
    setHiddenColumns,
    state: { globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 100000000000,
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.accessor),
      },
      autoResetPage: false,
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectAllRef) {
        hooks.visibleColumns?.push((columns) => [
          {
            id: "selection",
            orderBy: false,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => {
              return (
                <div className="text-center">
                  <input
                    type="checkbox"
                    ref={(el) => (selectAllRef.current[row?.original?.id] = el)}
                    value={row?.original?.id}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      }
    },
  );

  const [totalPages, setTotalPages] = useState(
    data[0]?.count ? Math.ceil(data[0]?.count / pageSize) : 1,
  );
  const [pageIndex, setPageIndex] = useState(
    batchParam?.offset ? (batchParam?.offset + pageSize) / pageSize : 1,
  );

  useEffect(() => {
    setTotalPages(Math.ceil(data[0]?.count / pageSize));
  }, [data[0]?.count]);

  useEffect(() => {
    // set hidden column
    setHiddenColumns(
      columns.filter((col) => col.show === false).map((col) => col.accessor),
    );
  }, [setHiddenColumns, columns]);

  useEffect(() => {
    gotoPage(0);
  }, [alphabetData]);

  useEffect(() => {
    if (globalFilter) {
      gotoPage(0);
    }
  }, [globalFilter]);

  useEffect(() => {
    setTotalPages(Math.ceil(data[0]?.count / pageSize));
    if (pageIndex > 0 && pageIndex <= Math.ceil(data[0]?.count / pageSize)) {
      setBatchParam({
        ...batchParam,
        offset: (pageIndex - 1) * pageSize,
        limit: pageSize,
      });
    } else {
      if (batchParam.offset !== 0) {
        setBatchParam({ ...batchParam, offset: 0, limit: pageSize });
      }
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    setPageIndex(1);
  }, [batchParam?.searchTerm]);

  const isPaginationDisabled = data.length === 0;
  return (
    <>
      <div className="d-flex justify-content-between">
        <ServerGlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setBatchParam={setBatchParam}
          batchParam={batchParam}
        />
        <span className="mx-2" />

        <span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPageIndex(1);
            }}
            className="form-control"
          >
            {pageSizeOptions.map((pageSizes) => (
              <option value={pageSizes.value}>Show {pageSizes.label}</option>
            ))}
          </select>
        </span>
      </div>

      <div className="table-striped table-responsive">
        <table
          {...getTableProps()}
          className="r-table table"
          style={{ maxHeight: "500px", overflow: "scroll" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => {
                  if (batchParam?.orderBy === column?.id) {
                    column.isSorted = true;
                    if (batchParam?.format === "DESC") {
                      column.isSortedDesc = true;
                      column.format = "DESC";
                    } else {
                      column.isSortedDesc = false;
                      column.format = "ASC";
                    }
                  } else {
                    column.isSorted = false;
                    column.isSortedDesc = false;
                  }
                  return (
                    <th
                      key={`th_${columnIndex}`}
                      className={
                        column.render("Header") === "Remarks"
                          ? "cursor-pointer text-center width-30"
                          : "cursor-pointer text-center sticky-column"
                      }
                    >
                      <div
                        className="d-inine"
                        onClick={(e) => {
                          console.log(column.orderBy);
                          if (
                            column.id !== "selection" &&
                            column.orderBy !== false
                          ) {
                            setBatchParam({
                              ...batchParam,
                              orderBy: column.id,
                              format: column.format === "DESC" ? "ASC" : "DESC",
                            });
                          }
                        }}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className="iconsminds-down" />
                            ) : (
                              <i className="iconsminds-up" />
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => (
                    <>
                      {cell.column.checkBox !== undefined ? (
                        <td>
                          <input
                            type="checkbox"
                            value={JSON.stringify(row.original)}
                            onChange={(e) => checkBoxChecked(e)}
                          />
                        </td>
                      ) : (
                        <td
                          key={`td_${cellIndex}`}
                          {...cell.getCellProps({
                            className: cell.column.cellClass + " td-cell-cls",
                          })}
                        >
                          {cell.render("Cell")}
                        </td>
                      )}
                    </>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td
                    {...column.getFooterProps({
                      className: column.cellClass,
                    })}
                  >
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>

      <div className="react-table-pagination d-flex justify-content-between my-3">
        <div className=""></div>
        <div className="">
          Page{" "}
          <strong>
            {data[0]?.count ? (
              <>
                {pageIndex} of {totalPages}
              </>
            ) : (
              <>{pageIndex} of 1</>
            )}
          </strong>
        </div>

        <div className="pagination-icon">
          <i
            className="simple-icon-control-start"
            onClick={() => {
              if (!isPaginationDisabled && pageIndex !== 1) {
                setPageIndex(1);
              }
            }}
            disabled={isPaginationDisabled || pageIndex === 1}
          />
          <i
            className="simple-icon-arrow-left mx-2"
            onClick={() => {
              if (!isPaginationDisabled && pageIndex !== 1) {
                setPageIndex(pageIndex - 1);
              }
            }}
            disabled={isPaginationDisabled || pageIndex === 1}
          />
          <i
            className="simple-icon-arrow-right mx-2"
            onClick={() => {
              if (!isPaginationDisabled && pageIndex < totalPages) {
                setPageIndex(pageIndex + 1);
              }
            }}
            disabled={
              isPaginationDisabled ||
              pageIndex >= totalPages ||
              totalPages === 1
                ? true
                : false
            }
          />
          <i
            className="simple-icon-control-end"
            onClick={() => {
              if (!isPaginationDisabled && pageIndex < totalPages) {
                setPageIndex(totalPages);
              }
            }}
            disabled={
              isPaginationDisabled ||
              pageIndex >= totalPages ||
              totalPages === 1
                ? true
                : false
            }
          />
        </div>
      </div>
    </>
  );
};

export default ServerMasterTable;
