import React, { Suspense, useState, useEffect, useRef } from "react";
import { Row, Col, Button, Card, Modal, Badge } from "react-bootstrap";
import { connect } from "react-redux";
import { gapi } from "gapi-script";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPen,
  faUserPlus,
  faMinus,
  faPlus,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";

import AILayout from "../../../components/layout/AILayout";
import ContactGroupModal from "./ContactGroupModal";

import {
  addContact,
  getContact,
  getGroup,
  storeContact,
  createGroup,
  InviteMember,
  getMyGroup,
} from "../../../actions/contact";
import FormLoader from "../../../components/FormLoader";
import AddContactModal from "./AddContactModal";
import ServerMasterTable from "../../../components/ServerMasterTable";
import GroupNameModal from "./GroupNameModal";
import GroupTable from "../../../components/GroupTable";
import AddGrpMember from "./AddGrpMember";
import MyGroupTable from "../../../components/MyGroupTable";
import ExitConfirmModal from "./ExitConfirmModal";

const ContactManager = (props) => {
  let addGroupModalData = {
    modalOpen: false,
    header: "",
    data: "",
  };
  const selectAllRef = useRef([]);
  const groupAllRef = useRef([]);
  const [contacts, setContacts] = useState([]);
  const [groupContacts, setGroupContacts] = useState([]);
  const [addGroupModal, setAddGroupModal] = useState(addGroupModalData);
  const [rowData, setRowData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isExitOpen, setExitIsOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeMenu, setActiveMenu] = useState("contacts");
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showMemberModal, setShowMemberModal] = useState(false);
  const [grpRowData, setGrpRowData] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [myGroup, setMyGroup] = useState([]);
  const [manageDetailsParam, setManageDetailsParam] = useState({
    limit: 50,
    offset: 0,
    searchTerm: "",
    orderBy: "",
    format: "DESC",
  });
  const [manageDetailsParam1, setManageDetailsParam1] = useState({
    limit: 50,
    offset: 0,
    searchTerm: "",
    orderBy: "",
    format: "DESC",
  });
  const [manageDetailsParam2, setManageDetailsParam2] = useState({
    limit: 50,
    offset: 0,
    searchTerm: "",
    orderBy: "",
    format: "DESC",
  });
  const [selectedRowData, setSelectedRowData] = useState([]);
  useEffect(() => {
    const selectedCards = selectAllRef?.current
      .filter((item) => item?.checked)
      .map((item) => parseInt(item?.value));
    setSelectedRowData(...selectedRowData, selectedCards);
  }, [selectAllRef]);

  useEffect(() => {
    props.getContact(manageDetailsParam);
  }, [manageDetailsParam]);
  useEffect(() => {
    props?.getGroup(manageDetailsParam1);
  }, [manageDetailsParam1]);
  useEffect(() => {
    props?.getMyGroup(manageDetailsParam2);
  }, [manageDetailsParam2]);
  useEffect(() => {
    if (props?.addedContact?.status) {
      props.getContact(manageDetailsParam);
    }
    if (
      // props?.addgroup?.status ||
      props?.removedMember?.status ||
      props?.addedMember?.status
    ) {
      props.getGroup(manageDetailsParam1);
    }
    setShowLoader(false);
    setIsOpen(false);
  }, [
    props?.addedContact,
    // props?.addgroup,
    props?.removedMember,
    props?.addedMember,
  ]);

  useEffect(() => {
    const updatedContacts = props?.contact?.map((contact, index) => ({
      ...contact,
      id: contact?.contact_id, // Adds a unique ID starting from 1
    }));
    setContacts(updatedContacts);
  }, [props?.contact]);
  useEffect(() => {
    const updatedGroupContacts = props?.groupDetails?.map((contact, index) => ({
      ...contact,
      id: contact?.group_id, // Adds a unique ID starting from 1
    }));
    setGroupContacts(updatedGroupContacts);
  }, [props?.groupDetails]);

  // useEffect(() => {
  //   if (grpRowData == null) {
  //     groupAllRef.current.checked = !!grpRowData;
  //   }
  // }, [grpRowData]);

  useEffect(() => {
    // Load the gapi client
    const initClient = () => {
      gapi.load("client:auth2", () => {
        gapi.client.init({
          apiKey: process.env.REACT_APP_GOOGLE_API, // Add your API Key here
          clientId: process.env.REACT_APP_CLIENT_ID,

          scope: "https://www.googleapis.com/auth/contacts.readonly",
          discoveryDocs: [
            "https://people.googleapis.com/$discovery/rest?version=v1",
          ],
        });
      });
    };

    initClient();
  }, []);

  const handleLogin = () => {
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then(() => {
        fetchContacts();
      });
  };

  const fetchContacts = () => {
    gapi.client.people.people.connections
      .list({
        resourceName: "people/me",
        pageSize: 1000, // Adjust the number of contacts to fetch
        personFields: "emailAddresses,names,phoneNumbers",
      })
      .then((response) => {
        const connections = response.result.connections || [];

        const importedContacts = connections.map((person) => ({
          name: person.names?.[0]?.displayName || null,
          email: person.emailAddresses?.[0]?.value || null,
          phone: person.phoneNumbers?.[0]?.value || null,
        }));
        const BATCH_SIZE = 100;
        setButtonLoader(true);
        props.storeContact({ contacts: importedContacts }, setButtonLoader);
        // Break connections into chunks of 100
        for (let i = 0; i < importedContacts.length; i += BATCH_SIZE) {
          const batch = importedContacts.slice(i, i + BATCH_SIZE);
          // props.storeContact({ contacts: batch });
        }
        // setContacts((prevContacts) => [...prevContacts, ...importedContacts]);
      })
      .catch((error) => {
        console.error("Error fetching contacts:", error);
        toast.error("Failed to fetch contacts.");
      });
  };

  const handleCheck = (row) => {
    setGrpRowData((prev) =>
      prev?.group_id === row?.original?.group_id ? null : row.original,
    );
  };
  const handleInvite = (row) => {
    if (row?.email) {
      let params = { email: row.email };
      props.InviteMember(params);
    } else {
      toast.error("Please enter an email address");
      setTimeout(() => {
        setRowData(row);
        setShowModal(true);
      }, 1000);
    }
  };
  const handleEdit = (row) => {
    setRowData(row.original);
    setShowModal(true);
  };
  const confirmDelete = (row) => {
    setRowData(row.original);
    setIsOpen(true);
  };
  const handleExitGroup = (row) => {
    let params = {
      ...manageDetailsParam2,
      group_id: rowData?.group_id,
      is_exit: 1,
    };
    setShowLoader(true);
    setExitIsOpen(false);
    props?.getMyGroup(params);
    // props?.createGroup(params);
  };

  const confirmExitGroup = (row) => {
    setRowData(row.original);
    setExitIsOpen(true);
    console.log(row);
  };
  const handleDelete = () => {
    if (activeMenu === "contacts") {
      let params = {
        contact_id: rowData.contact_id ? rowData.contact_id : "",
      };
      setShowLoader(true);
      props.addContact(params);
    } else {
      let params = {
        group_id: rowData?.group_id,
      };
      setShowLoader(true);
      props?.createGroup(params);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "S No",
        accessor: "sno",
        cellClass: "text-center",
        Cell: (props) => (
          <>
            {parseInt(props?.row?.id) + 1 + parseInt(manageDetailsParam.offset)}
          </>
        ),
        sortType: "basic",
      },
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
        cellClass: "text-center",
        sortType: "basic",
      },
      {
        Header: "Email",
        accessor: "email",
        cellClass: "text-center",
        sortType: "basic",
      },
      {
        Header: "Mobile Number",
        accessor: "phone",
        cellClass: "text-center",
        sortType: "basic",
      },
      {
        Header: "Member",
        accessor: "member",
        cellClass: "text-center",
        sortType: "basic",
        Cell: ({ row }) => {
          return (
            <>
              {row?.original?.is_invitable ? (
                <Badge
                  onClick={() => handleInvite(row?.original)}
                  size="sm"
                  className="btn btn-primary"
                >
                  Invite
                </Badge>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "Group",
        accessor: "groups",
        cellClass: "text-center",
        sortType: "basic",
        Cell: ({ row }) => {
          return (
            <>
              {row?.original?.groups ? (
                <span
                  className="text-primary bolder"
                  onClick={() => {
                    if (row?.original?.groups.length) {
                      setShowGroupModal(true);
                      setRowData(row.original);
                    } else {
                      toast.error("No groups found");
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                  <b>{row?.original?.groups.length}</b>
                </span>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: "Action",
        cellClass: "text-center",
        Cell: ({ row }) => {
          return (
            <div className="d-flex align-items-center justify-content-center ">
              <FontAwesomeIcon
                icon={faTrash}
                className="text-muted mx-2 "
                style={{ cursor: "pointer" }}
                onClick={() => confirmDelete(row)}
              />
              <FontAwesomeIcon
                icon={faPen}
                className="text-muted mx-2"
                style={{ cursor: "pointer" }}
                onClick={() => handleEdit(row)}
              />
            </div>
          );
        },
        sortType: "basic",
      },
    ],
    [],
  );
  const col = React.useMemo(
    () => [
      {
        id: "expander",
        Header: () => null,
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <FontAwesomeIcon icon={faMinus} className="mx-1 text-muted" />
            ) : (
              <FontAwesomeIcon icon={faPlus} className="mx-1 text-muted" />
            )}
          </span>
        ),
      },
      {
        Header: "Select",
        id: "select",
        Cell: ({ row }) => (
          <input
            type="radio"
            name={`checkbox-${row.original.group_id}`} // Unique name for each row
            className="mx-2"
            checked={grpRowData?.group_id === row.original.group_id} // Ensure only one is checked
            onChange={() => handleCheck(row)}
          />
        ),
      },
      {
        Header: "Group Name",
        accessor: "group_name",
      },
      {
        Header: "Description",
        accessor: "group_description",
      },
      {
        Header: "Group Members",
        accessor: "members",
        Cell: ({ row }) => {
          return <div className="ms-5">{row?.original?.members?.length}</div>;
        },
      },
      {
        Header: "Action",

        Cell: ({ row }) => {
          return (
            <FontAwesomeIcon
              icon={faTrash}
              className="text-muted mx-2 "
              style={{ cursor: "pointer" }}
              onClick={() => confirmDelete(row)}
            />
          );
        },
        sortType: "basic",
      },
    ],
    [grpRowData],
  );

  const mygrpcol = React.useMemo(
    () => [
      {
        id: "expander",
        Header: () => null,
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <FontAwesomeIcon icon={faMinus} className="mx-1 text-muted" />
            ) : (
              <FontAwesomeIcon icon={faPlus} className="mx-1 text-muted" />
            )}
          </span>
        ),
      },
      {
        Header: "Select",
        id: "select",
        Cell: ({ row }) => (
          <input
            type="radio"
            name={`checkbox-${row.original.group_id}`} // Unique name for each row
            className="mx-2"
            checked={grpRowData?.group_id === row.original.group_id} // Ensure only one is checked
            onChange={() => handleCheck(row)}
          />
        ),
      },
      {
        Header: "Group Name",
        accessor: "group_name",
      },
      {
        Header: "Description",
        accessor: "group_description",
      },
      {
        Header: "Group Members",
        accessor: "members",
        Cell: ({ row }) => {
          return <div className="ms-5">{row?.original?.members?.length}</div>;
        },
      },
      {
        Header: "Action",

        Cell: ({ row }) => {
          return (
            <div className="mx-2">
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="text-muted "
                style={{ cursor: "pointer", fontSize: "16px" }}
                onClick={() => confirmExitGroup(row)}
              />
            </div>
          );
        },
        sortType: "basic",
      },
    ],
    [grpRowData],
  );

  const handleAddContact = (
    values,
    resetForm,
    setSubmitting,
    setFieldValue,
  ) => {
    let params = {
      name: values.name,
      email: values.email,
      phone: `+${values.mobile}`,
      ...(rowData?.contact_id && { contact_id: rowData.contact_id }), // Add contact_id only if it exists
      location: values.location,
    };
    props.addContact(params, resetForm, setSubmitting, setFieldValue);
    setRowData("");
    setShowModal(false); // Close the modal after adding contact
  };

  const handleClose = () => {
    setShowModal(false);
    setRowData("");
  };
  const handleGroupClose = () => {
    setShowGroupModal(false);
    setRowData("");
  };
  const handleGroupMember = () => {
    setShowMemberModal(false);
    setGrpRowData(null);
  };

  const handleMember = () => {
    if (grpRowData?.group_id) {
      setShowMemberModal(true);
    } else {
      props?.groupDetails.length > 0
        ? toast.error("Please select a group.")
        : toast.error("No groups found. Please create a group first.");
    }
  };
  const AddNewGroup = (propsData) => {
    const selectedCards = selectAllRef?.current
      .filter((item) => item?.checked)
      .map((item) => parseInt(item?.value));

    if (selectedCards?.length > 0) {
      setAddGroupModal({
        ...addGroupModal,
        ...{
          modalOpen: true,
          header: "Add new Group",
          data: selectedCards,
        },
      });
    } else {
      contacts.length > 0
        ? toast.error("Please select at least one member.")
        : toast.error("No contacts found. Please add a new contact.");
    }
  };

  const toggleModal = () => {
    setAddGroupModal({ ...addGroupModal, ...addGroupModalData });
  };

  const handleClick = (data) => {
    if (data === "contacts") {
      setActiveMenu("contacts");
    } else if (data === "managegroup") {
      setActiveMenu("managegroup");
    } else {
      setActiveMenu("mygroup");
      setManageDetailsParam({
        limit: 50,
        offset: 0,
        searchTerm: "",
        orderBy: "",
        format: "DESC",
      });
    }
  };

  return (
    <AILayout>
      <Row>
        <Col md="auto " className="mx-auto">
          <Card
            id="sidebar"
            className="mt-3 contact-tab-css "
            style={{ background: "none" }}
          >
            <div
              // id={props.activeColor}
              className={`${
                activeMenu === "contacts"
                  ? "item sub-item contact-active-tab-css"
                  : "item sub-item contact-Inactive-tab-css"
              }`}
              onClick={() => handleClick("contacts")}
            >
              <span className="sidebar-text text-truncate">Contacts</span>
            </div>
            <div
              // id={props.activeColor}
              className={`${
                activeMenu === "managegroup"
                  ? "item sub-item contact-active-tab-css"
                  : "item sub-item contact-Inactive-tab-css"
              }`}
              onClick={() => handleClick("managegroup")}
            >
              <span className="sidebar-text text-truncate">
                Groups (
                {props?.groupDetails?.length > 0
                  ? props?.groupDetails[0]?.count
                  : 0}
                )
              </span>
            </div>
            <div
              // id={props.activeColor}
              className={`${
                activeMenu === "mygroup"
                  ? "item sub-item contact-active-tab-css"
                  : "item sub-item contact-Inactive-tab-css"
              }`}
              onClick={() => handleClick("mygroup")}
            >
              <span className="sidebar-text text-truncate">
                My Groups (
                {props?.myGroupDetails?.length > 0
                  ? props?.myGroupDetails[0]?.count
                  : 0}
                )
              </span>
            </div>
          </Card>
        </Col>

        <Col md={12}>
          {activeMenu === "contacts" ? (
            <Card
              className="px-md-5 px-sm-0 py-3 w-100"
              style={{ maxHeight: "75vh" }}
            >
              <Row className="justify-content-end mb-2">
                <div className="col-auto mb-2">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShowModal(true)}
                  >
                    <FontAwesomeIcon icon={faUserPlus} className="mx-1" />
                    Create Contact
                  </Button>
                </div>
                <div className="col-auto mb-2">
                  {/* <Button variant="primary">Import Google Contact</Button> */}

                  <Button variant="primary" size="sm" onClick={handleLogin}>
                    {buttonLoader ? <FormLoader /> : "Import Google Contact"}
                  </Button>
                </div>
                <div className="col-auto mb-2">
                  <Button onClick={() => AddNewGroup()} size="sm">
                    <i
                      className="bi bi-people-fill mx-1"
                      style={{ fontSize: "14px" }}
                    ></i>
                    Create New Group
                  </Button>
                </div>
              </Row>
              {/* <MasterTable
                columns={columns}
                data={contacts.length ? contacts : []}
                selectAllRef={selectAllRef}
              /> */}
              <ServerMasterTable
                columns={columns}
                data={contacts.length ? contacts : []}
                selectAllRef={selectAllRef}
                batchParam={manageDetailsParam}
                setBatchParam={setManageDetailsParam}
              />
            </Card>
          ) : activeMenu == "managegroup" ? (
            <Card className="px-5 py-3 w-100" style={{ maxHeight: "75vh" }}>
              <Row className="justify-content-end mb-2">
                <div className="col-auto mb-2"></div>
                <div className="col-auto mb-2">
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      handleMember();
                    }}
                  >
                    <FontAwesomeIcon icon={faUserPlus} className="mx-1" />
                    Add Member
                  </Button>
                </div>
              </Row>
              {/* <ServerMasterTable
                columns={col}
                data={groupContacts.length ? groupContacts : []}
                selectAllRef={groupAllRef}
                batchParam={manageDetailsParam1}
                setBatchParam={setManageDetailsParam1}
              /> */}
              <GroupTable
                data={props?.groupDetails.length ? props?.groupDetails : []}
                batchParam={manageDetailsParam1}
                setBatchParam={setManageDetailsParam1}
                setGrpRowData={setGrpRowData}
                columns={col}
              />
            </Card>
          ) : (
            <>
              <Card className="px-5 py-3 w-100" style={{ maxHeight: "75vh" }}>
                <MyGroupTable
                  data={
                    props?.myGroupDetails.length ? props?.myGroupDetails : []
                  }
                  batchParam={manageDetailsParam2}
                  setBatchParam={setManageDetailsParam2}
                  setGrpRowData={setGrpRowData}
                  columns={mygrpcol}
                />
              </Card>
            </>
          )}
        </Col>
      </Row>

      <Suspense>
        <ContactGroupModal
          modalData={addGroupModal}
          toggleModal={toggleModal}
          setActiveMenu={setActiveMenu}
        />
      </Suspense>
      <Suspense>
        <ExitConfirmModal
          isOpen={isExitOpen}
          setIsOpen={setExitIsOpen}
          handleExitGroup={handleExitGroup}
        />
      </Suspense>
      <Suspense>
        <AddContactModal
          showModal={showModal}
          handleClose={handleClose}
          rowData={rowData}
          handleAddContact={handleAddContact}
          setShowModal={setShowModal}
        />
      </Suspense>
      <Suspense>
        <GroupNameModal
          showModal={showGroupModal}
          setShowModal={setShowGroupModal}
          handleClose={handleGroupClose}
          rowData={rowData}
        />
      </Suspense>
      <Suspense>
        <AddGrpMember
          showModal={showMemberModal}
          setShowModal={setShowMemberModal}
          handleClose={handleGroupMember}
          rowData={grpRowData}
        />
      </Suspense>
      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal delete-modal"
      >
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">
            Are you sure want to delete?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="d-flex flex-column align-items-center">
            <div className="rounded-circle bg-light d-flex align-items-center justify-content-center modal-check-failure">
              <i className="fas fa-times-circle"></i>{" "}
            </div>
            If you delete this, you won't be able to recover the data.
          </div>
        </Modal.Body>
        <Modal.Footer className=" justify-content-center ">
          <button
            type="button"
            className={`btn w-25 mb-button my-1 btn-${props.activeColor}`}
            onClick={() => handleDelete()}
          >
            {showLoader ? <FormLoader /> : <>Yes</>}
          </button>
          <button
            type="button"
            className="btn mb-button mb-button-cancel w-25 my-1"
            onClick={() => setIsOpen(false)}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </AILayout>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  contact: state.contact.contact,
  addedContact: state.contact.addContact,
  groupDetails: state.contact.group,
  addgroup: state.contact.addgroup,
  removedMember: state.contact.removedMember,
  addedMember: state.contact.addedMember,
  myGroupDetails: state.contact.mygroup,
});

export default connect(mapStateToProps, {
  addContact,
  getContact,
  getGroup,
  storeContact,
  createGroup,
  InviteMember,
  getMyGroup,
})(ContactManager);
