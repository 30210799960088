import React, { useState, useEffect, Suspense } from "react";

import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  ToggleButtonGroup,
  ToggleButton,
  Card,
} from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";

import Axios from "../../config/axios.js";
import {
  getUserPlan,
  getUserRole,
  getUserRoleId,
  setUserMenus,
  setUserPlan,
} from "../../utils/UserDetails";
import AILayout from "../../components/layout/AILayout";
import PurchaseConfirmModal from "./PurchaseConfirmModal";
import {
  getSubscription,
  getUserCurrentPlan,
  updatePlan,
} from "../../actions/subscription.js";

const SubscriptionPage = (props) => {

  const [selectedDuration, setSelectedDuration] = useState("monthly");
  const [role, setRole] = useState(getUserRole().toLowerCase());
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [allFeatures, setAllFeatures] = useState([]);
  const [tableData, setTableData] = useState([]);
  const Duration = ["monthly", "quarterly", "yearly"];
  const history = useHistory();

  // const allRoles = ["student", "trainer", "recruiter"];

  useEffect(() => {
    props.getSubscription({ role_id: getUserRoleId() });
    props.getUserCurrentPlan({ role_id: getUserRoleId() })
  }, []);

  useEffect(() => {

    if (props?.suscriptionDetails?.length) {
      setTableData(props.suscriptionDetails);
    }
  }, [props?.suscriptionDetails]);

  useEffect(() => {
    if (props?.suscriptionPlans) {
      setPlans(props.suscriptionPlans.data?.plans)
      setAllFeatures(props.suscriptionPlans.data?.features);
    }
  }, [props?.suscriptionPlans]);

  const currentRole = plans?.find((r) => r.role === role);

  const currentDuration = currentRole?.durations.find(
    (d) => d.period === selectedDuration,
  );

  const getActivePlan = (plans, currentData) => {
    const activePlanName = currentData?.data?.[0]?.plan_name?.membership_plan_name;
    const activePlan = plans?.find(plan => plan.name === activePlanName);
    return activePlan;
  }

  const activePlan = getActivePlan(plans, props?.userPlan);

  const renderIcon = (availability) => {
    if (availability === null) return "-";
    return availability ? (
      <FontAwesomeIcon
        icon={faCheck}
        style={{ color: "green", fontSize: "14px" }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faTimes}
        style={{ fontSize: "14px", color: "red" }}
      />
    );
  };

  const handleBuyNowClick = (plan) => {
    setSelectedPlan(plan);
    setIsOpen(true);
  };

  const handlePayment = async () => {
    setLoading(true);
    if (!selectedPlan || selectedPlan.price === 0) {
      toast.error("No payment required for this plan!");
      setIsOpen(false);
      setLoading(false);
      return;
    }

    try {
      // Call backend to create Razorpay order
      const response = await Axios.post("/create-order", {
        user_id: getUserPlan().user_id,
        amount: selectedPlan.price * 100, // Convert price to paise
        currency: "INR",
        plan_id: selectedPlan?.membership_id,
      });

      const order = response.data.payment;
      setLoading(false);
      setIsOpen(false);
      // Razorpay payment options

      const options = {
        key: process.env.RAZOR_PAY, // Replace with your Razorpay key ID
        amount: order.amount,
        currency: order.currency,
        name: "TJunction",
        description: `Subscription: ${selectedPlan.name}`,
        order_id: order.razorpay_order_id, // Order ID from the backend
        handler: async function (response) {
          // Step 3: Payment Successful - Verify Payment

          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
            response;

          try {
            const verifyResponse = await Axios.post("/verify-payment", {
              payment_id: razorpay_payment_id,
              order_id: razorpay_order_id,
              signature: razorpay_signature,
            });
    
            if (verifyResponse?.data?.status === true) {

              toast.success("Payment verified successfully!");
              props.updatePlan({ membership_id: selectedPlan?.membership_id }, history);

            } else {
              toast.error("Payment verification failed!");
            }
          } catch (verifyError) {
            console.error("Error verifying payment:", verifyError);
            toast.error(" Please contact support.");
          }
        },
        prefill: {
          name: props?.userProfiles[0]?.user?.first_name,
          email: props?.userProfiles[0]?.user?.email,
          contact: props?.userProfiles[0]?.user?.phone_number,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();

      razorpay.on("payment.failed", function (response) {
        toast.error(`Payment failed! Reason: ${response.error.description}`);
        setIsOpen(false);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error(" Please try again later.");
      setIsOpen(false);
      setLoading(false);
    }
  };

  return (
    <AILayout>
      <div className="h-100 row font-css ">
        {/* {tableData.length ? ( */}
        <div className="">
          <Row className="px-2 mt-3">
            {/* <Col className=" d-flex justify-content-center ">
                <div className="text-center">
                  <h1>
                    <b className="text-capitalize text-primary">
                      {getUserRole().toLowerCase()} Subscription Plan{" "}
                    </b>
                  </h1>
                </div>
              </Col> */}
            <div>
              <h4
                className={`p-2 mb-3 text-center`}
                style={{ borderRadius: 6 }}
              >
                <b className="text-capitalize text-primary">
                  {getUserRole().toLowerCase()} Subscription Plan{" "}
                </b>
              </h4>
            </div>
            {/* <Col md={6}>
              <div className="d-flex justify-content-center mb-3">
                {allRoles.map((data, index) => (
                  <button
                    className={`btn ${
                      role === data ? "btn-primary" : "btn-outline-primary"
                    } mx-2`}
                    onClick={() => setRole(data)}
                  >
                    {data}
                  </button>
                ))}
              </div>
            </Col> */}
          </Row>
          <Row className="px-2 mt-3">
            <Col md={12} xl={12} className="mx-auto">
              {/* <div className="d-flex justify-content-center mb-3">
                <div className="button-group-css">
                  {Duration.map((plan) => (
                    <button
                      key={plan}
                      className={`button-css-sub cursor-pointer ${selectedDuration === plan
                        ? "active active-plan-style"
                        : ""
                        }`}
                      onClick={() => setSelectedDuration(plan)}
                    >
                      {plan.charAt(0).toUpperCase() + plan.slice(1)}
                      {selectedDuration === plan && (
                        <span style={{ marginLeft: "5px" }}>
                          <BsFillCheckCircleFill />
                        </span>
                      )}
                    </button>
                  ))}
                </div>
              </div> */}

              <div className="pricing-table">
                {props.suscriptionPlans && props.suscriptionPlans?.data ?
                  <div className="table-container">
                    <Table className="table-style">
                      <col className="menu-columns" />
                      <col className="menu-columns" />
                      {plans?.map((plan, index) => (
                        <col
                          className={`data-columns ${activePlan?.name === plan.name ? "active-column" : ""
                            }`}
                        />
                      ))}
                      <thead>
                        <tr style={{ backgroundColor: '#fff' }}>
                          <th>
                            <span className="plan-price-css text-primary">
                              Features
                            </span>
                          </th>
                          <th>
                            <span className="plan-price-css text-primary">
                              Sub Features
                            </span>
                          </th>
                          {plans?.map((plan, index) => (
                            <th
                              key={index}
                              // style={{ background: activePlan?.name === plan.name ? 'linear-gradient(179deg, rgba(0, 255, 145, .19) 1.21%, rgba(0, 255, 145, .19) 98.8%)' : '#fff' }}
                              className={`text-center ${index === plans.length - 1
                                ? "column-radius-css"
                                : ""
                                } ${activePlan?.name === plan.name ? "active-column" : ""} `}
                            >
                              <button
                                className={`th-button-css btn-color${index + 1}`}
                              >
                                {plan.name}
                              </button>
                            </th>
                          ))}
                        </tr>
                        <tr style={{ backgroundColor: '#fff' }}>
                          <th className="text-center">
                            <span>&nbsp;</span>
                          </th>
                          <th className="text-center">
                            <span>&nbsp;</span>
                          </th>
                          {plans?.map((plan, index) => (
                            <th
                              key={index}
                              // style={{ background: activePlan?.name === plan.name ? 'linear-gradient(179deg, rgba(0, 255, 145, .19) 1.21%, rgba(0, 255, 145, .19) 98.8%)' : '#fff' }}
                              className={`text-center ${index === plans.length - 1
                                ? "column-radius-css"
                                : ""
                                } ${activePlan?.name === plan.name ? "active-column" : ""} `}
                            >
                              <span className="d-flex justify-content-center align-items-center">
                                <span className="plan-price-css">₹{plan.price}&nbsp;</span>
                                {(plan.Validity_days > 0) ?
                                  <span className="plan-duration-css">/&nbsp;{plan.Validity_days} days</span>
                                  : ''}
                              </span>
                            </th>
                          ))}
                        </tr>
                        <tr style={{ backgroundColor: '#fff' }}>
                          <th className="text-center"></th>
                          <th className="text-center"></th>
                          {plans?.map((plan, index) => (
                            <th
                              key={index}
                              // style={{ background: activePlan?.name === plan.name ? 'linear-gradient(179deg, rgba(0, 255, 145, .19) 1.21%, rgba(0, 255, 145, .19) 98.8%)' : '#fff' }}
                              className={`text-center padding-btm-css ${activePlan?.name === plan.name ? "active-column" : ""
                                }`}>
                              {activePlan && activePlan?.name === plan.name ?
                                '' :
                                <button
                                  className={`th-buy-now-btn-css ${activePlan?.name === plan.name ||
                                    plan.price <= 0
                                    ? "disable-btn"
                                    : ""
                                    }`}
                                  onClick={() => handleBuyNowClick(plan)}
                                  disabled={
                                    activePlan?.name === plan.name ||
                                    plan.price <= 0
                                  }
                                >
                                  {plan.price <= 0
                                    ? "Free Trial"
                                    : "Buy Now"}
                                </button>}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {allFeatures?.length
                          ? allFeatures?.map((module, moduleIndex) => (
                            <React.Fragment key={`${moduleIndex}`}>
                              {module.submodules?.map((subModule, subIndex) => {
                                const accessKeys = Object.keys(subModule.access);
                                return (
                                  <tr key={`${moduleIndex}-${subIndex}`}>
                                    {subIndex === 0 && (
                                      <td
                                        rowSpan={
                                          module.submodules.filter((subModule, index, self) =>
                                            index === self.findIndex((s) => s.name === subModule.name)
                                          ).length
                                        }
                                        className="qboard-cell"
                                      >
                                        {module.name || ""}
                                      </td>
                                    )}
                                    <td>{subModule.name || ""}</td>
                                    {accessKeys.map((key) => (
                                      <td key={`${moduleIndex}-${subIndex}-${key}`} className="text-center">
                                        {renderIcon(subModule.access[key])}
                                      </td>
                                    ))}
                                  </tr>
                                )
                              })}
                            </React.Fragment>
                          ))
                          : ""}
                      </tbody>
                    </Table>
                  </div>
                  :
                  <Loader
                    type="ThreeDots"
                    color="#00BFFF"
                    height={"80vh"}
                    width={50}
                    className="loader"
                  />
                }
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Suspense>
        <PurchaseConfirmModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedPlan={selectedPlan}
          Duration={selectedDuration}
          handlePayment={handlePayment}
          loading={loading}
        />
      </Suspense>
    </AILayout>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  userProfiles: state.profile.userProfiles,
  suscriptionDetails: state.profile.suscriptionDetails,
  suscriptionPlans: state.profile.suscriptionPlans,
  userPlan: state.subscription.userPlan,
  updatedPlanData: state.subscription.updatedPlanData,
});

export default connect(mapStateToProps, {
  getSubscription,
  getUserCurrentPlan,
  updatePlan,
})(SubscriptionPage);
