import React, { useState, useCallback } from "react";
import { useAsyncDebounce } from "react-table";
import debounce from "lodash/debounce";

const ServerGlobalFilter = ({ setBatchParam, batchParam }) => {
  const [value, setValue] = useState("");
  // const onChange = (value) => {
  //   setBatchParam({ ...batchParam, offset: 0, searchTerm: value });
  // };

  const onChange = (value) => {
    fetchSearchResults(value);
  };

  const fetchSearchResults = useCallback(
    debounce(async (query) => {
      try {
        setBatchParam((prevBatchParam) => ({
          ...prevBatchParam,
          offset: 0,
          searchTerm: query,
        }));
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }, 1000),
    [],
  );

  return (
    <span>
      <input
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Search"
      />
    </span>
  );
};

export default ServerGlobalFilter;
