// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("api_token");
  if (userStr) return JSON.parse(userStr);
  else return null;
};
// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("api_token");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("userrole");
  localStorage.removeItem("userRoleId");
  localStorage.removeItem("userplan");
  localStorage.removeItem("expires_in");
  localStorage.removeItem("menu");
  localStorage.removeItem("userData");
  localStorage.removeItem("googleuser");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("MasterRoles");
  localStorage.clear();
};
// set the token and user from the session storage
export const setUserSession = (user) => {
  localStorage.setItem("api_token", JSON.stringify(user));
};

export const setUserData = (userdata) => {
  localStorage.setItem("userData", JSON.stringify(userdata));
};
export const setGoogleUser = (userdata) => {
  localStorage.setItem("googleuser", JSON.stringify(userdata));
};

export const getUserData = () => {
  const userStr = localStorage.getItem("userData");
  if (userStr) return JSON.parse(userStr);
  else return null;
};
export const getGoolgleUser = () => {
  const userStr = localStorage.getItem("googleuser");
  if (userStr) return JSON.parse(userStr);
  else return false;
};

export const getUserMenus = () => {
  const userMenuData = localStorage.getItem("menu");
  if (userMenuData) return JSON.parse(userMenuData);
  else return null;
};

export const setUserMenus = (data) => {
  localStorage.setItem("menu", JSON.stringify(data));
};

export const setTokenExpires = (user) => {
  localStorage.setItem("expires_in", JSON.stringify(user));
};

export const getTokenExpires = () => {
  const expiresAt = localStorage.getItem("expires_in");
  if (expiresAt !== undefined) return JSON.parse(expiresAt);
  else return null;
};

export const setUserRole = (role) => {
  localStorage.setItem("userrole", JSON.stringify(role));
};

export const setUserRoleId = (role) => {
  localStorage.setItem("userRoleId", JSON.stringify(role));
};

export const getUserRole = () => {
  const role = localStorage.getItem("userrole");
  if (role !== undefined) return JSON.parse(role);
  else return null;
};

export const getUserRoleId = () => {
  const roleId = localStorage.getItem("userRoleId");
  if (roleId !== undefined) return JSON.parse(roleId);
  else return null;
};

export const setUserPlan = (plan) => {
  localStorage.setItem("userplan", JSON.stringify(plan));
};

export const getUserPlan = () => {
  const plan = localStorage.getItem("userplan");
  if (plan !== undefined) return JSON.parse(plan);
  else return null;
};

export const getLimitData = () => {
  const data = localStorage.getItem("limitData");
  return data ? JSON.parse(data) : null;
};

export const getLimitforFitRole = () => {
  const data = localStorage.getItem("limitforfitrole");
  return data ? JSON.parse(data) : null;
};

export const getLimitforEnhanceJD = () => {
  const data = localStorage.getItem("limitEnchanceJD");
  return data ? JSON.parse(data) : null;
};

export const getLimitforRR = () => {
  const data = localStorage.getItem("limitforRR");
  return data ? JSON.parse(data) : null;
};

export const getLimitforSortResume = () => {
  const data = localStorage.getItem("sort-resume");
  return data ? JSON.parse(data) : null;
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken");
  // if (data !== undefined) return JSON.parse(data);
  // else return null;
};

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};
