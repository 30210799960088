import Axios from "../config/axios";
import { getUserRoleId } from "../utils/UserDetails";

class SubscriptionService {
  getSubscription = (requestData) => {
    return Axios.get("/get-all-plans", {
      params: requestData
    })
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  getUserCurrentPlan = (requestData) => {
    return Axios.get("/get-user-plans", {
      params: requestData
    })
      .then(function (response) {
        const {
          data
        } = response;
        return data;
      }).catch((error) => {
        console.log(error);
      })
  }

  changeRole = (requestData) => {
    return Axios.post("/change-role-menu", requestData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updatePlan = (requestData) => {
    requestData.role_id = getUserRoleId();
    return Axios.post("/update-plan", requestData)
      .then(function (response) {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

}
const subscriptionServiceInstance = new SubscriptionService();
export default subscriptionServiceInstance;