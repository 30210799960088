import {
  SET_SUSCRIPTION_PLAN,
  GET_USER_PLAN,
  CHANGE_ROLE,
  UPDATE_PLAN_DETAILS,
} from "../actions/types";

const initialState = {
  suscriptionPlans: {},
  userPlan: {},
  changedRoleData:{},
  updatedPlanData: {},
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUSCRIPTION_PLAN: {
      return {
        ...state,
        suscriptionPlans: action.payload,
      };
    }
    case GET_USER_PLAN: {
      return {
        ...state,
        userPlan: action.payload,
      };
    }
    case CHANGE_ROLE: {
      return {
        ...state,
        changedRoleData: action.payload,
      };
    }
    case UPDATE_PLAN_DETAILS: {
      return {
        ...state,
        updatedPlanData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default subscriptionReducer;
