import { toast } from "react-toastify";
import ContactService from "../services/contact.service";
import {
  ADD_CONTACT,
  GET_CONTACT,
  CREATE_CONTACT,
  GET_GROUP,
  ADD_MEMBER,
  GET_GRPCONTACT,
  REMOVE_MEMBER,
  GET_MYGROUP,
} from "./types";

export const addContact =
  (data, resetForm, setSubmitting, setFieldValue, setShowLoader) =>
  async (dispatch) => {
    try {
      const resultData = await ContactService.addContact(data);
      if (setSubmitting) {
        setSubmitting(false);
      }

      if (resultData?.status) {
        dispatch({
          type: ADD_CONTACT,
          payload: resultData,
        });
        data?.contact_id
          ? data?.name
            ? toast.success("Updated Contact")
            : toast.error("Deleted Contact")
          : toast.success("Added Contact");
      } else {
        toast.error("Please Try Again");
      }
    } catch (err) {
      console.log(err);
      toast.error("Please Try Again");
    }
  };

export const getContact = (data) => async (dispatch) => {
  try {
    const resultData = await ContactService.getContact(data);

    if (resultData?.status) {
      dispatch({
        type: GET_CONTACT,
        payload: resultData?.data,
      });
    } else {
      toast.error("Please Try Again");
    }
  } catch (err) {
    console.log(err);
    toast.error("Please Try Again");
  }
};
export const createGroup =
  (data, resetForm, setSubmitting, setFieldValue, setActiveMenu) =>
  async (dispatch) => {
    try {
      const resultData = await ContactService.createGroup(data);
      if (setSubmitting) {
        setSubmitting(false);
      }

      if (resultData?.status) {
        dispatch({
          type: CREATE_CONTACT,
          payload: resultData,
        });
        if (data?.group_name) {
          setActiveMenu("managegroup");
          toast.success("Group created successfully");
        } else if (data?.group_id) {
          toast.error("Deleted Group");
        }
      } else {
        toast.error("Please Try Again");
      }
    } catch (err) {
      console.log(err);
      toast.error("Please Try Again");
    }
  };

export const getGroup = (data) => async (dispatch) => {
  try {
    const resultData = await ContactService.getGroup(data);

    if (resultData?.status) {
      dispatch({
        type: GET_GROUP,
        payload: resultData?.data,
      });
    } else {
      toast.error("Please Try Again");
    }
  } catch (err) {
    console.log(err);
    toast.error("Please Try Again");
  }
};

export const getMyGroup = (data) => async (dispatch) => {
  try {
    const resultData = await ContactService.getMyGroup(data);
    // const resultData = {
    //   status: "true",
    //   data: [
    //     {
    //       group_id: 23,
    //       group_name: "Family",
    //       group_description: "family group",
    //       user_id: 70,
    //       status_id: 1,
    //       created_at: "2025-02-05 10:40:48",
    //       updated_at: "2025-02-05 10:40:48",
    //       count: 1,
    //       members: [
    //         {
    //           id: 71,
    //           group_id: 23,
    //           contact_id: 218,
    //           created_at: "2025-02-05 10:40:48",
    //           updated_at: "2025-02-05 10:40:48",
    //           contact: {
    //             contact_id: 218,
    //             name: "Appa",
    //             email: null,
    //             phone: null,
    //             location: null,
    //             user_id: 70,
    //             status_id: 1,
    //             created_at: "2025-02-03 17:05:06",
    //             updated_at: "2025-02-03 17:05:06",
    //           },
    //         },
    //       ],
    //     },
    //   ],
    // };
    if (resultData?.status) {
      dispatch({
        type: GET_MYGROUP,
        payload: resultData?.data,
      });
      if (data?.is_exit) {
        toast.success("You have exited the group successfully.");
      }
    } else {
      toast.error("Please Try Again");
    }
  } catch (err) {
    console.log(err);
    toast.error("Please Try Again");
  }
};

export const storeContact = (data, setButtonLoader) => async (dispatch) => {
  try {
    const resultData = await ContactService.storeContact(data);
    setButtonLoader(false);

    if (resultData?.status) {
      dispatch({
        type: ADD_CONTACT,
        payload: resultData,
      });
      toast.success("Contact stored successfully");
    } else {
      toast.error("Please Try Again");
    }
  } catch (err) {
    console.log(err);
    toast.error("Please Try Again");
  }
};

export const addMember =
  (data, setShowModal, setLoader) => async (dispatch) => {
    try {
      const resultData = await ContactService.addMember(data);
      setLoader(false);
      if (resultData?.status) {
        dispatch({
          type: ADD_MEMBER,
          payload: resultData,
        });
        setShowModal(false);
        toast.success("Contact added to the group successfully.");
      } else {
        toast.error("Please Try Again");
      }
    } catch (err) {
      console.log(err);
      toast.error("Please Try Again");
      setLoader(false);
    }
  };

export const getGrpContact = (data) => async (dispatch) => {
  try {
    const resultData = await ContactService.getGrpContact(data);

    if (resultData?.status) {
      dispatch({
        type: GET_GRPCONTACT,
        payload: resultData?.data,
      });
    } else {
      toast.error("Please Try Again");
    }
  } catch (err) {
    console.log(err);
    toast.error("Please Try Again");
  }
};

export const removeMember =
  (data, setIsOpen, setShowLoader) => async (dispatch) => {
    try {
      const resultData = await ContactService.removeMember(data);
      setIsOpen(false);
      setShowLoader(false);
      if (resultData?.status) {
        dispatch({
          type: REMOVE_MEMBER,
          payload: resultData,
        });
        toast.error("Deleted successfully");
      } else {
        toast.error("Please Try Again");
      }
    } catch (err) {
      console.log(err);
      toast.error("Please Try Again");
    }
  };

export const InviteMember = (data) => async (dispatch) => {
  try {
    const resultData = await ContactService.InviteMember(data);

    if (resultData?.status) {
      toast.success("Invited successfully");
    } else {
      toast.error("Please Try Again");
    }
  } catch (err) {
    console.log(err);
    toast.error("Please Try Again");
  }
};
