import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Image, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { addMember, getContact, getGrpContact } from "../../../actions/contact";
import ServerMasterTable from "../../../components/ServerMasterTable";
import { toast } from "react-toastify";
import FormLoader from "../../../components/FormLoader";
const AddGrpMember = ({
  contact,
  showModal,
  handleClose,
  rowData,
  getContact,
  addMember,
  setShowModal,
  getGrpContact,
}) => {
  const [manageDetailsParam, setManageDetailsParam] = useState({
    limit: 50,
    offset: 0,
    searchTerm: "",
    orderBy: "",
    format: "DESC",
  });
  const [allContacts, setAllContacts] = useState([]);
  const [loader, setLoader] = useState(false);

  const selectAllRef = useRef([]);
  useEffect(() => {
    setManageDetailsParam({
      limit: 50,
      offset: 0,
      searchTerm: "",
      orderBy: "",
      format: "DESC",
    });
  }, [showModal]);
  useEffect(() => {
    const updatedContacts = contact?.map((contact, index) => ({
      ...contact,
      id: contact?.contact_id, // Adds a unique ID starting from 1
    }));

    setAllContacts(updatedContacts);
  }, [contact]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name", // accessor is the "key" in the data
        cellClass: "text-center",
        sortType: "basic",
      },
      {
        Header: "Email",
        accessor: "email",
        cellClass: "text-center",
        sortType: "basic",
      },
      {
        Header: "Mobile Number",
        accessor: "phone",
        cellClass: "text-center",
        sortType: "basic",
      },
    ],
    [],
  );

  useEffect(() => {
    if (showModal) {
      let params = {
        ...manageDetailsParam,
        group_id: rowData?.group_id,
      };

      getGrpContact(params);
    }
  }, [manageDetailsParam, showModal]);

  const handleAddMember = () => {
    const selectedCards = selectAllRef?.current
      .filter((item) => item?.checked)
      .map((item) => parseInt(item?.value));
    console.log(selectedCards);
    let params = {
      // group_name: rowData.group_name,
      group_id: rowData.group_id,
      // group_description: rowData.group_description,
      contact_ids: selectedCards,
    };
    if (selectedCards?.length) {
      {
        setLoader(true);
        addMember(params, setShowModal, setLoader);
      }
    } else {
      toast.error("Please select atleast one member");
    }
  };
  return (
    <>
      {" "}
      <Modal
        show={showModal}
        onHide={() => handleClose()}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
        size="lg"
      >
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">
            Contact List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-5">
          <div className="mb-2 text-end">
            <Button
              variant="primary"
              size="sm"
              onClick={() => handleAddMember()}
            >
              {loader ? <FormLoader /> : "Add"}
            </Button>
          </div>
          <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
            <ServerMasterTable
              columns={columns}
              data={allContacts.length ? allContacts : []}
              selectAllRef={selectAllRef}
              batchParam={manageDetailsParam}
              setBatchParam={setManageDetailsParam}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  contact: state.contact.groupMember,
});

export default connect(mapStateToProps, {
  getContact,
  addMember,
  getGrpContact,
})(AddGrpMember);
