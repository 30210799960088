import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  modalData,
  toggleModal,
  handleFunction,
  activeColor,
  loading,
}) => {

  return (
    <Modal
      show={isOpen}
      onHide={() => toggleModal()}
      backdrop="static"
      keyboard={false}
      centered
      className="tj-modal"
    >
      <Modal.Body className="text-center py-4">
        <h5 style={{ fontWeight: 600 }}>{modalData?.header}</h5>
        {/* <div>
          <FontAwesomeIcon
            icon={faExclamation}
            style={{color:"#ffc107", fontSize: "35px" }}
          />
        </div> */}
      </Modal.Body>
      <Modal.Footer className=" justify-content-center ">
        <Button
          type="button"
          variant="outline-primary"
          className={`my-1 px-4 py-1`}
          onClick={() => {
            toggleModal()
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className={`my-1 px-4 py-1 btn-${activeColor}`}
          onClick={() => handleFunction()}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Ok"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, {})(ConfirmationModal);
