import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode"; // For decoding Google JWT token

import AuthService from "../../services/auth.service";
import {
  getUser,
  getUserRole,
  setGoogleUser,
  setRefreshToken,
  setUserData,
  setUserMenus,
  setUserPlan,
  setUserRole,
  setUserRoleId,
  setUserSession,
} from "../../utils/UserDetails";
import Axios from "../../config/axios";
import { useLocation } from "react-router-dom";

const GoogleLoginComponent = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState(null);
  const [roleType, setRoleType] = useState(
    props?.activeRole ? props.activeRole : "",
  );

  useEffect(() => {
    // Load the Google script and initialize
    if (props.activeRole) {
      setRoleType(props.activeRole);
    }
    loadGoogleScript().then((google) => {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_CLIENT_ID, // Replace with your actual Google Client ID
        callback: responseGoogleSuccess, // Callback function for login success
        auto_select: false,
      });

      // Create a hidden Google button wrapper
      const wrapper = createGoogleButtonWrapper(google);
      setGoogleButtonWrapper(wrapper); // Store the wrapper in state
    });
  }, []);

  const loadGoogleScript = () => {
    return new Promise((resolve, reject) => {
      if (window.google) {
        resolve(window.google);
      } else {
        const script = document.createElement("script");
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        script.onload = () => resolve(window.google);
        script.onerror = reject;
        document.body.appendChild(script);
      }
    });
  };

  const createGoogleButtonWrapper = (google) => {
    const wrapper = document.createElement("div");
    wrapper.style.display = "none"; // Hide the wrapper
    document.body.appendChild(wrapper);

    google.accounts.id.renderButton(wrapper, {
      type: "icon", // Hidden button, type does not matter
      width: "200",
    });

    const button = wrapper.querySelector("div[role='button']");
    return {
      click: () => button.click(), // Simulate button click
    };
  };

  const handleCustomGoogleLogin = () => {
    if (googleButtonWrapper) {
      googleButtonWrapper.click(); // Trigger the hidden Google button click
    } else {
      toast.error("Google login is not initialized. Please try again.");
    }
  };

  const responseGoogleSuccess = async (credentialResponse) => {
    try {
      const decoded = jwt_decode(credentialResponse.credential);

      const googleData = {
        first_name: decoded.given_name,
        last_name: decoded.family_name,
        email: decoded.email,
        provider: "google",
        provider_id: decoded.sub,
        token: credentialResponse.credential,
        refresh_token: decoded.jti,
        expires_in: decoded.exp,
      };

      if (googleData.email) {
        register(googleData);
      } else {
        toast.error("Please try again!");
      }
    } catch (error) {
      toast.error("Failed to process Google response.");
    }
  };

  const register = async (googleData) => {
    let emailData = { email: googleData.email };

    let userData = await AuthService.checkUserExist(emailData);

    if (userData.status === true) {
      let resultData = await AuthService.googleLogin(googleData);

      if (resultData?.status && resultData.status === true) {
        Axios.interceptors.request.use(function (config) {
          config.headers["Authorization"] = resultData?.data?.api_token
            ? `Bearer ${resultData.data.api_token}`
            : "";
          return config;
        });

        let userData = resultData?.data ? resultData.data : "";
        setUserData(userData);
        setUserSession(userData?.api_token);
        setUserRole(userData?.user_role);
        setUserRoleId(userData?.role_id);
        setUserMenus(userData?.menus);
        setUserPlan(userData?.plan);
        setRefreshToken(userData?.refresh_token);
        setGoogleUser(true);

        if (getUser()) {
          setTimeout(() => {
            history.push("/");
          }, 500);
        }
      } else {
        toast.error("Please Try Again!");
      }
    } else {
      googleRegister(googleData);
    }
  };

  const googleRegister = async (googleData) => {
    if (googleData) {
      // googleData.role_type = props.activeRole;
      const updatedGoogleData = {
        ...googleData,
        role_type: roleType,
      };

      let resultData = await AuthService.googleRegister(updatedGoogleData);

      if (resultData?.status && resultData.status === true) {
        history.push({
          pathname: "/interestmodal",
          state: {
            role: resultData?.data?.role_type,
            profileId: resultData?.data?.profile_id,
          },
        });
      } else {
        toast.error("Please Try Again!");
      }
    }
  };

  return (
    <div>
      <button
        className="btn  d-flex align-content-around justify-content-center custom-google-logi"
        // to="#"
        onClick={handleCustomGoogleLogin}
      >
        <span
          style={{
            marginBottom: "7px",
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL + "/images/icons/icon-google-search.svg"
            }
            width={18}
            height={18}
          />
        </span>
        <div style={{ marginBottom: 5, paddingLeft: 0 }}>
          Continue with Google
        </div>
      </button>
    </div>
  );
};

export default GoogleLoginComponent;
