import axiosInstance from "../config/axios";
import subscriptionService from "../services/subscription.service";
import {
  getUserRole,
  setRefreshToken,
  setTokenExpires,
  setUserData,
  setUserMenus,
  setUserPlan,
  setUserRole,
  setUserRoleId,
  setUserSession
} from "../utils/UserDetails";
import {
  SET_SUSCRIPTION_PLAN,
  GET_USER_PLAN,
  CHANGE_ROLE,
  UPDATE_PLAN_DETAILS,
} from "./types";
import { toast } from "react-toastify";

export const getSubscription = (data) => async (dispatch) => {
  try {
    const resultData = await subscriptionService.getSubscription(data);

    dispatch({
      type: SET_SUSCRIPTION_PLAN,
      payload: resultData,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getUserCurrentPlan = (data) => async (dispatch) => {
  try {
    const resultData = await subscriptionService.getUserCurrentPlan(data);

    dispatch({
      type: GET_USER_PLAN,
      payload: resultData,
    });
  } catch (err) {
    console.log(err);
  }
};

export const changeRole = (data, setLoading, history) => async (dispatch) => {

  const role = data?.role_type?.charAt(0).toUpperCase() + data?.role_type?.slice(1).toLowerCase();

  try {
    const resultData = await subscriptionService.changeRole(data);

    if (resultData?.status && resultData.status === true) {
      setLoading(false)
      toast.success("Role switched to " + role + " " + "successfully!")
      dispatch({
        type: CHANGE_ROLE,
        payload: resultData,
      });

      let userData = resultData?.data ? resultData.data : "";
      if (userData?.user_role) {
        setUserMenus(userData?.menus);
        setUserData(userData);
        setUserSession(userData?.api_token);
        setUserRole(userData?.user_role);
        setUserRoleId(userData?.role_id);
        setUserPlan(userData?.plan);
        setRefreshToken(userData?.refresh_token);
        if (userData?.expiry_time) {
          setTokenExpires(userData?.expiry_time);
        }
        if (userData?.api_token && userData.api_token) {
          axiosInstance.interceptors.request.use(function (config) {
            config.headers.Authorization = `Bearer ${userData.api_token}`;
            return config;
          });
        }
        const location = history.location.pathname;
        const lastSegment = location.split("/").pop();
        history.push(`/${getUserRole()?.toLowerCase()}/${lastSegment}`);
      } else {
        history.push({
          pathname: "/interestmodal",
          state: {
            role: userData?.user_role,
            profileId: userData?.profile_id,
          },
        });
      }
    } else {
      setLoading(false)
      dispatch({
        type: CHANGE_ROLE,
        payload: resultData,
      });
      toast.error(resultData?.message ? resultData?.message : "Please Try Again");
      history.push("/subscription")
    }
  } catch (err) {
    setLoading(false)
    console.log(err);
  }
};

export const updatePlan = (data, history) => async (dispatch) => {
  try {
    const resultData = await subscriptionService.updatePlan(data);
    if (resultData?.status && resultData.status === true) {

      let userData = resultData?.data ? resultData.data : "";

      setUserMenus(userData?.menus);
      setUserData(userData);
      setUserSession(userData?.api_token);
      setUserPlan(userData?.plan);
      setRefreshToken(userData?.refresh_token);
      if (userData?.expiry_time) {
        setTokenExpires(userData?.expiry_time);
      }
      if (userData?.api_token && userData.api_token) {
        axiosInstance.interceptors.request.use(function (config) {
          config.headers.Authorization = `Bearer ${userData.api_token}`;
          return config;
        });
      }
      history.push("/");
    }
    else {
      dispatch({
        type: UPDATE_PLAN_DETAILS,
        payload: resultData,
      });
      toast.error(resultData?.message ? resultData?.message : "Please Try Again");
    }
  } catch (err) {
    console.log(err);
  }
};