import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser, getUserRole } from "../utils/UserDetails";

function AuthRoute({ component: Component, ...rest }) {
  let redirectLink = "/";

  if (getUserRole() === "TRAINER") {
    redirectLink = "/trainer/home";
  } else if (getUserRole() === "STUDENT") {
    redirectLink = "/student/home";
  } else if (getUserRole() === "RECRUITER") {
    redirectLink = "/recruiter/home";
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        getUser() ? (
          <Redirect
            to={{
              pathname: redirectLink,
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

export default AuthRoute;
