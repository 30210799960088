import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { AlertCircle, CircleX } from "lucide-react";

const CommonLimitModal = ({
  isOpen,
  setIsOpen,
  activeColor,
}) => {
  console.log(isOpen)
  const history = useHistory()

  const handleClick = () => {
    history.push("/subscription")
    setIsOpen(false)
  }

  return (
    <Modal
      show={isOpen}
      onHide={() => setIsOpen(false)}
      backdrop="static"
      keyboard={false}
      centered
      className="tj-modal delete-modal"
    >
       <Modal.Header>
        <Modal.Title>Upgrade Required</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center justify-content-center modal-check-failure">
           <CircleX className="limit" />
          </div>
          You've reached the limit for
          {/* <strong></strong> */}
          . Upgrade your plan to continue using this feature.
        </div>
      </Modal.Body>
      <Modal.Footer className=" justify-content-center ">
        <button
          type="button"
          className={`btn w-25 mb-button my-1 btn-${activeColor}`}
          onClick={() => handleClick()}
        >
          Upgrade
        </button>
        <button
          type="button"
          className="btn mb-button mb-button-cancel w-25 my-1"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, {})(CommonLimitModal);
